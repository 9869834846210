import type { Contract } from '@repo/db/schema'
import { Badge } from '@repo/ui/components/Badge.js'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@repo/ui/components/Tooltip.js'
import { cn } from '@repo/ui/utils/utils'
import { type ComponentProps, useMemo } from 'react'

const statusConfig: Record<
  Contract['status'],
  { label: string; color: ComponentProps<typeof Badge>['variant'] }
> = {
  active: { label: 'Active', color: 'success' },
  expired: { label: 'Inactive', color: 'destructive' },
  pending_renewal: { label: 'Pending Renewal', color: 'warning' },
  pending_cancellation: { label: 'Pending Cancellation', color: 'warning' },
  pending_upgrade: { label: 'Pending Upgrade', color: 'warning' },
  under_review: { label: 'Under Review', color: 'warning' },
  future: { label: 'Upcoming', color: 'indigo' },
  draft: { label: 'Draft', color: 'secondary' }
}

export const ContractStatusBadge = ({
  status,
  ...props
}: {
  status: Contract['status']
} & ComponentProps<typeof Badge>) => {
  const isPending = useMemo(
    () => status.includes('pending') || status === 'under_review',
    [status]
  )

  if (!isPending) {
    return (
      <Badge variant={statusConfig[status].color} {...props}>
        {statusConfig[status].label}
      </Badge>
    )
  }

  return (
    <Tooltip>
      <TooltipTrigger>
        <Badge
          variant={statusConfig[status].color}
          {...props}
          className={cn('underline decoration-dotted', props.className)}
        >
          <Icon name={'progress'} />
          In Progress
        </Badge>
      </TooltipTrigger>
      <TooltipContent>
        This contract is{' '}
        <span className="lowercase">{statusConfig[status].label}</span>.
      </TooltipContent>
    </Tooltip>
  )
}
